<template>
  <v-card
    no-gutter
    style="width: 100% !important; height: 55% !important"
    class="align-center justify-center text-center"
  >
    <v-card-title class="headline mb-2">
      {{ $t("tag.tagPool.tagPool") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="this.$t('common.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="tagsInPoolFiltered"
        :loading="loading"
        class="logtable"
        :search="search"
      >
        <template v-slot:[`item.status`] v-if="tagsInPoolFiltered.length > 0">
          <v-icon fab color="green">mdi-account</v-icon>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">{{
          humanDate(item.createdAt)
        }}</template>

        <template v-slot:[`item.crud`]="{ item }">
          <v-icon fab color="green" @click="dialogOpen(item)" class="mr-5"
            >mdi-view-grid-plus</v-icon
          >
        </template>

        <template v-slot:[`item.crudHide`]="{ item }">
          <v-icon fab color="grey" @click="hideTag(item)"
            >mdi-file-hidden</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-card-title class="headline mb-2">
            {{ $t("tag.tagPool.hiddenTags") }}
            <v-spacer></v-spacer>
          </v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text>
            <v-data-table
              :headers="hiddenHeaders"
              :items="hiddenTags"
              :loading="loading"
              class="logtable"
              :search="search"
            >
              <template v-slot:[`item.status`] v-if="hiddenTags.length > 0">
                <v-icon fab color="green">mdi-account</v-icon>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">{{
                humanDate(item.createdAt)
              }}</template>

              <template v-slot:[`item.crud`]="{ item }">
                <v-icon fab color="green" @click="dialogOpen(item)" class="mr-5"
                  >mdi-view-grid-plus</v-icon
                >
              </template>

              <template v-slot:[`item.crudHide`]="{ item }">
                <v-icon fab color="grey" @click="hideTag(item)"
                  >mdi-file-hidden</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "TagPool",

  data() {
    return {
      search: "",
      searchHidden: "",
      loading: false,
      hiddenTags: [],
      tagsInPoolFiltered: [],
      headers: [
        { text: this.$t("tag.tagPool.deveui"), value: "deveui" },
        { text: this.$t("tag.tagPool.createdAt"), value: "createdAt" },
        { text: this.$t("tag.tagPool.take"), value: "crud" },
        { text: this.$t("tag.tagPool.hide"), value: "crudHide" },
      ],

      hiddenHeaders: [
        { text: this.$t("tag.tagPool.deveui"), value: "deveui" },
        { text: this.$t("tag.tagPool.createdAt"), value: "createdAt" },
        { text: this.$t("tag.tagPool.show"), value: "crudHide" },
      ],
    };
  },

  methods: {
    ...mapActions("tag", ["getTagPool", "updateTagPoolStatus"]),

    dialogOpen(item) {
      // Open dialog to create a new tag from this tag
      this.$router.push(`/tag/create/${item.deveui}`);
    },

    hideTag(item) {
      this.updateTagPoolStatus(item.deveui);
    },
  },

  computed: {
    ...mapState("tag", ["tagsInPool"]),
  },

  async mounted() {
    await this.getTagPool();
  },

  watch: {
    tagsInPool() {
      this.tagsInPoolFiltered = this.tagsInPool.filter((e) => !e.hidden);
      this.hiddenTags = this.tagsInPool.filter((e) => e.hidden);
    },
  },
};
</script>
